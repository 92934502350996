<template>
    <div class="pages p20">
        <div class="title-btn-block">
            <div class="title-block">{{ $t("message.my_online_works") }}</div>
            <div>
              <router-link  :to="{ name: 'onlineWorkAdd', params: { id: staffId} }">
                  <div class="my-btn mr-2 el-button" style="background:#00CC00; color:#fff; margin-right: 10px;">
                      Создать
                  </div>
              </router-link>
                <router-link :to="{ name: 'Home' }">
                    <div class="my-btn cr1 el-button">
                        <i class="el-icon-d-arrow-left"></i> {{$t('message.back')}}
                    </div>
                </router-link>
            </div>
        </div>
        <div class="content-block">
            <div class="datetime-end bg p20 mb20">
                <el-divider content-position="left">{{ $t("message.my_online_works") }}</el-divider>
                <div class="scrol-table crmhome__scroll my-table">
                  <table >
                        <thead>
                            <tr>
                                <th>{{ $t("message.user") }}</th>
                                <th>{{ $t("message.reason") }}</th>
                                <th>{{ $t("message.left_date") }}</th>
                                <th>{{ $t("message.come_date") }}</th>
                                <th>{{ $t("message.status") }}</th>
                                <!-- <th>{{ $t("message.comment") }}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="assent in lists" :key="assent.id">
                                <td>{{assent.staff.name}} {{assent.staff.last_name}} {{assent.staff.middle_name}}</td>
                                <td>{{assent.reason}}</td>
                                <td>{{assent.date}} {{assent.from_time}}</td>
                                <td>{{assent.date}} {{assent.to_time}}</td>
                                <td v-if="assent.status == 'accept'" style="background: #00a838">{{$t('message.received')}}</td>
                                <td v-if="assent.status == 'deny'" style="background: #ba0615">{{$t('message.denied')}}</td>
                                <td v-if="assent.status == 'waiting'" style="background: #E6A23C">{{ $t("message.waiting") }}</td>
                                <!-- <td>{{assent.comment}}</td> -->
                            </tr>
                            
                        </tbody>
                    </table>
                </div>
            </div>

             
        </div>
    </div>
</template>
<script>
import { getItem } from "@/utils/storage";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      salarys:null,
      assents:[],
      staffId:getItem("userId"),
      advance_salary:null
    };
  },
  mounted() {
    this.getTable()
  },
  computed:{
    ...mapGetters({
      lists:"onlineWork/list",
    })
  },
  methods: {
    ...mapActions({
      updateList: "onlineWork/index",
    }),

    getTable() {
      this.updateList(getItem("userId"))
        .then((res) => {
          this.assents = res.take_assent
          this.vacations = res.vacation
          this.advance_salary = res.advance_salary
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};

</script>
<style lang="scss">

</style>
